import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d3e2ebf4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "sidebar__notification"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: { name: _ctx.to, params: _ctx.params },
    class: _normalizeClass(["link", { active: _ctx.isActive }])
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true),
      (_ctx.notification)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.notification), 1))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["to", "class"]))
}